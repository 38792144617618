import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as _91account_93ii65Kj50n9Meta } from "/app/pages/account-center/[account].vue?macro=true";
import { default as index788RETMXqCMeta } from "/app/pages/account-center/index.vue?macro=true";
import { default as bug_45reportR0ErPggrFsMeta } from "/app/pages/contact/bug-report.vue?macro=true";
import { default as generalaWM2q8PN36Meta } from "/app/pages/contact/general.vue?macro=true";
import { default as indexNnUfSBY2UkMeta } from "/app/pages/contact/index.vue?macro=true";
import { default as suggestionsbZJbkqVo4kMeta } from "/app/pages/contact/suggestions.vue?macro=true";
import { default as disclaimer_45and_45copyrightVVpHKoJa6eMeta } from "/app/pages/disclaimer-and-copyright.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as leaderboardpoR4f5fZL8Meta } from "/app/pages/leaderboard.vue?macro=true";
import { default as _91_46_46_46slug_93fpzVAOvnUWMeta } from "/app/pages/learn/[...slug].vue?macro=true";
import { default as glossaryEIYqQQWviBMeta } from "/app/pages/learn/glossary.vue?macro=true";
import { default as indexEWtmILIolaMeta } from "/app/pages/learn/index.vue?macro=true";
import { default as _91slug_93uS71yKNSMVMeta } from "/app/pages/learn/tags/[slug].vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutu8Sb32ihwPMeta || {},
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "account-center-account",
    path: "/account-center/:account()",
    meta: _91account_93ii65Kj50n9Meta || {},
    component: () => import("/app/pages/account-center/[account].vue")
  },
  {
    name: "account-center",
    path: "/account-center",
    meta: index788RETMXqCMeta || {},
    component: () => import("/app/pages/account-center/index.vue")
  },
  {
    name: "contact-bug-report",
    path: "/contact/bug-report",
    meta: bug_45reportR0ErPggrFsMeta || {},
    component: () => import("/app/pages/contact/bug-report.vue")
  },
  {
    name: "contact-general",
    path: "/contact/general",
    meta: generalaWM2q8PN36Meta || {},
    component: () => import("/app/pages/contact/general.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexNnUfSBY2UkMeta || {},
    component: () => import("/app/pages/contact/index.vue")
  },
  {
    name: "contact-suggestions",
    path: "/contact/suggestions",
    meta: suggestionsbZJbkqVo4kMeta || {},
    component: () => import("/app/pages/contact/suggestions.vue")
  },
  {
    name: "disclaimer-and-copyright",
    path: "/disclaimer-and-copyright",
    meta: disclaimer_45and_45copyrightVVpHKoJa6eMeta || {},
    component: () => import("/app/pages/disclaimer-and-copyright.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "leaderboard",
    path: "/leaderboard",
    component: () => import("/app/pages/leaderboard.vue")
  },
  {
    name: "learn-slug",
    path: "/learn/:slug(.*)*",
    component: () => import("/app/pages/learn/[...slug].vue")
  },
  {
    name: "learn-glossary",
    path: "/learn/glossary",
    meta: glossaryEIYqQQWviBMeta || {},
    component: () => import("/app/pages/learn/glossary.vue")
  },
  {
    name: "learn",
    path: "/learn",
    meta: indexEWtmILIolaMeta || {},
    component: () => import("/app/pages/learn/index.vue")
  },
  {
    name: "learn-tags-slug",
    path: "/learn/tags/:slug()",
    component: () => import("/app/pages/learn/tags/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    component: () => import("/app/pages/privacy-policy.vue")
  }
]