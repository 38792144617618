<template>
  <div>
    <NuxtLayout>
      <section class="mx-auto max-w-screen-xl flex justify-center">
        <div class="container px-12 lg:py-20 py-12 flex lg:flex-row flex-col">
          <div class="basis-1/2 flex justify-center items-center">
            <img class="h-80 sm:h-96" src="/404.png" alt="" />
          </div>
          <div class="basis-1/2 text-center">
            <p class="font-bold lg:text-8xl text-7xl text-orange-400 text-center">404錯誤</p>
            <h1 class="mt-3 text-2xl font-semibold text-neutral-800 md:text-3xl">
              糟糕！我們找不到您要訪問的頁面。
            </h1>
            <h2 class="">這個頁面可能已被移動或不再可用。</h2>
            <h2 class="">別擔心，您可以試試這些選項：</h2>

            <div class="flex items-center mt-6 gap-x-3 justify-center">
              <NuxtLink
                to="/"
                class="px-5 py-2 tracking-wide text-white transition-colors duration-200 bg-blue-700 rounded-lg shrink-0 sm:w-auto hover:bg-blue-800"
              >
                返回首頁
              </NuxtLink>
            </div>

            <div class="mt-10 space-y-6">
              <div>
                <NuxtLink
                  to="/account-center"
                  class="inline-flex items-center text text-blue-700 gap-x-2 hover:underline"
                >
                  <span>搜尋帳戶</span>
                  <Icon name="ic:baseline-arrow-right-alt" class="w-5 h-5 rtl:rotate-180" />
                </NuxtLink>

                <p class="mt-2 text-neutral-800 text-center">試試看搜尋其他帳戶</p>
              </div>

              <div>
                <NuxtLink
                  to="/learn"
                  class="inline-flex items-center text-blue-700 gap-x-2 hover:underline"
                >
                  <span>學習中心</span>
                  <Icon name="ic:baseline-arrow-right-alt" class="w-5 h-5 rtl:rotate-180" />
                </NuxtLink>

                <p class="mt-2 text-neutral-800 text-center">閱讀我們最新的文章</p>
              </div>

              <div>
                <NuxtLink
                  to="/contact"
                  class="inline-flex items-center text-blue-700 gap-x-2 hover:underline"
                >
                  <span>聯絡我們</span>
                  <Icon name="ic:baseline-arrow-right-alt" class="w-5 h-5 rtl:rotate-180" />
                </NuxtLink>

                <p class="mt-2 text-neutral-800 text-center">請告訴我們您的想法</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </NuxtLayout>
  </div>
</template>
